<!--
 * @Descripttion: 弹窗组件
 * @version: 0.1
 * @Author: luoxi
 * @Date: 2019年9月7日11:7:37
 * @LastEditors: seven
 * @LastEditTime: 2019-09-18 20:17:46
 -->
<template>
  <el-dialog
    append-to-body
    custom-class="filelayer"
    :title="layer.title"
    :visible.sync="layer.show"
    :fullscreen="layer.fullscreen"
    :modal="true"
    :width="width"
    :top="top"
    :close-on-click-modal="false"
    :show-close="false"
  >
    <slot></slot>
    <div class="submit" v-if="layer.showButtons">
      <el-button @click="layer.show = false" class="cancel-button">{{ falseLabel }}</el-button>
      <el-button type="primary" @click="onSubmit" class="ok-button">{{ trueLabel }}</el-button>
    </div>
  </el-dialog>
</template>

<script>
  export default {
    components: {

    },
    props: {
      'layer': {
        type: Object,
        default: () => {
          return {
            show: false,
            title: '我是一个标题',
            showButtons: false,
            fullscreen: false,
            trueLabel: '确认',
            falseLabel: '取消'
          }
        }
      },
      'width': {
        type: String,
        default: '600px'
      },
      'top': {
        type: String,
        default: '6vh'
      },
      'trueLabel': {
        type: String,
        default: '确认'
      },
      'falseLabel': {
        type: String,
        default: '取消'
      }
    },
    data() {
      return {
        
      }
    },
    methods: {
      // 作者：罗茜
      // 时间：2019年9月7日11:12:40
      // 作用：弹窗提交事件触发
      onSubmit(){
        this.$emit('submit',this.layer.title)
      }
    }
  }
</script>

<style scoped="" lang="scss">
  /deep/ .filelayer{
    border-radius: 10px;
    .el-dialog__header{
      padding: 20px;
      border-bottom: 1px solid #B9CBD7;
    }
    .el-dialog__title{
      font-size:20px;
      font-family:Microsoft YaHei;
      font-weight:bold;
      color:rgba(102,120,140,1);
    }
    .submit{
      text-align: center;
      margin-top: 20px;
      .cancel-button{
        margin-right: 20px;
        background:rgba(185,203,215,1);
        font-size:18px;
        font-family:Microsoft YaHei;
        font-weight:400;
        color:rgba(255,255,255,1);
        padding: 11px 36px 9px;
        border-radius: 10px;
        &:hover{
          background-color: #9dadb7;
          border-color: #9dadb7;
        }
      }
      .ok-button{
        font-size:18px;
        font-family:Microsoft YaHei;
        font-weight:400;
        color:rgba(255,255,255,1);
        padding: 11px 36px 9px;
        border-radius: 10px;
        &:hover{
          background-color: #2c75d8;
          border-color: #2c75d8;
        }
      }
    }
    .is-fullscreen{
      display: flex;
      flex-direction: column;
      .el-dialog__body{
        flex: 1;
      }
    }
  }
</style>
